.qquantity-input {
  // display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}
.qquantity-input:focus {
  background: red;
}

.qquantity-input__modifier,
.qquantity-input__screen {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
}

.qquantity-input__modifier {
  padding: 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1rem;
  background: #ffffff;
  color: rgb(0, 0, 0);
  border: 0 solid #dbdbdb;
  text-align: center;
  cursor: pointer;
}
.qquantity-input__modifier:hover {
  background: #dadada;
  color: #000000;
}

.qquantity-input__screen {
  width: 4.5rem;
  height: 4rem;
  padding: 0.7rem;
  font-size: 0.7rem;
  border: 0;
  text-align: center;
}
