@import 'styles/scss/theme/theme.scss';
// components
@import 'styles/scss/abstracts/mixins';
@import 'components/index';

// pages
@import 'pages/index';
@import 'containers/index';

//containers
@import 'containers/index';
// external lib
@import '~react-pro-sidebar/dist/scss/styles.scss';
@import '~react-dates/lib/css/_datepicker.css';
