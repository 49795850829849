.search-bar {
  width: '20%';
}

@media (max-width: 992px) {
  .logo-moncef {
    width: 90px !important;
  }
}

.activeNav {
  color: #940c64 !important;
  border-bottom: #940c64 solid;
}
.activeNavMobile {
  color: #940c64 !important;
  border-bottom: #940c64 solid;
  width: 15%;
}
.nav-item a:hover,
.nav-item a:focus {
  color: #940c64 !important;
}
.nav-item button:hover,
.nav-item button:focus {
  color: #940c64 !important;
}
