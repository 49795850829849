@import 'NotFound/NotFound';
@import 'FactureDetailPage/FactureDetails';
@import 'CheckoutPage/style.scss';

.custom-control-label {
  display: flex;
  align-items: center;
}

.custom-control-label::before {
  margin-right: 5px;
  position: unset !important;
}
.wrapper {
  position: relative;
  top: 0;
  height: 100vh;

  &.wrapper-full-page {
    min-height: 100vh;
    height: auto;
  }
}
