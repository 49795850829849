.factureContainer {
  background: white;
  display: inline-block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}
.factureContainer[size='A4'] {
  width: 21cm;
  height: 20.7cm;
}

@media print {
  body {
    counter-reset: page;
  }

  .factureContainer {
    margin: 0;
    display: block;
    box-shadow: 0;
  }
}
body {
  counter-reset: page;
}
.page-counter {
  font-weight: bold;
  position: absolute;
}
.page-counter:after {
  counter-increment: page;
  content: 'Page ' counter(page);
}
.x {
  height: 300px;
}
.tableFix {
  width: 100%;
  border: solid;
  table-layout: fixed;
  border-collapse: collapse;
}

table tr td {
  page-break-inside: always;
}
