.quantity-input {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}
.quantity-input:focus {
  background: red;
}

.quantity-input__modifier,
.quantity-input__screen {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
}

.quantity-input__modifier {
  padding: 0.7rem;
  width: 2.5rem;
  height: 2.8rem;
  font-size: 1rem;
  background: #ffffff;
  color: rgb(0, 0, 0);
  font-weight: 'bold';
  border: 0 solid #dbdbdb;
  text-align: center;
  cursor: pointer;
}
.quantity-input__modifier:hover {
  background: #dadada;
  color: #000000;
}
.quantity-input__modifier--left {
  border-radius: 0 4px 4px 0;
}
.quantity-input__modifier--right {
  border-radius: 4px 0 0 4px;
}

.quantity-input__screen {
  width: 2.5rem;
  height: 2.5rem;
  font-size: 0.8rem;
  border: 0;
  border-top: 0 solid #dbdbdb;
  border-bottom: 0 solid #dbdbdb;
  text-align: center;
}
