#main-tables {
  padding: 30px 0;
}

.doc-table {
  border: 1px solid #940c64;
  border-radius: 5px;
  transition: all 0.2s linear;
}
.doc-table:hover {
  box-shadow: 0px 3px 3px 0px #b847916c;
  transition: all 0.2s linear;
}
.doc-table .plan-header {
  background: #ba7575;
  color: #fff;
  text-transform: capitalize;
  padding: 2px 0;
}
.doc-table .plan-header h3 {
  margin: 0;
  padding: 20px 0 5px 0;
  text-transform: uppercase;
}
.doc-table .plan-price {
  color: #fff;
  padding: 10px 0;
  margin: 0;
  font-size: 30px;
  border-top: 2px solid #cb9898;
  font-weight: bold;
}

.doc-submit {
  border-radius: 30px;
  height: 60px;
  display: inline-block;
  text-decoration: none;
  padding: 10px 40px;
  border: 1px solid #940c64;
  color: #940c64;
  font-size: 15px;
  text-transform: uppercase;
}

.doc-table .hvr-bounce-to-right {
  border-radius: 60px;

  display: inline-block;
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 1px transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  transition-property: color;
  transition-duration: 0.5s;
}
.doc-table .hvr-bounce-to-right:before {
  border-radius: 60px;
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #940c64;
  transform: scaleX(0);
  transform-origin: 0 100%;
  transition-property: transform;
  transition-duration: 0.5s;
  transition-timing-function: ease-out;
}
.doc-table .hvr-bounce-to-right:hover,
.doc-table .hvr-bounce-to-right:focus,
.doc-table .hvr-bounce-to-right:active {
  color: white;
}
.doc-table .hvr-bounce-to-right:hover:before,
.doc-table .hvr-bounce-to-right:focus:before,
.doc-table .hvr-bounce-to-right:active:before {
  border-radius: 60px;
  transform: scaleX(1);
  transition-timing-function: cubic-bezier(1, 2, 0.37, 0.66);
}
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #940c64;
  border-color: #940c64 transparent #940c64 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.product-item {
  background-size: contain;
  width: 50px;
  height: 50px;
  background-position: center;
  background-repeat: no-repeat;
}
