.testimonial-group > .row {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}
.testimonial-group > .row > .item {
  display: inline-block;
}

.scroll {
  ::-webkit-scrollbar {
    height: 5px;
    scroll-behavior: smooth;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #940c64;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #940c64;
  }
}

.subItemActivated {
  padding: 10px;
  border-style: solid;
  border-color: #940c64;
  border-width: 1px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.subItem {
  padding: 10px;
  border-style: solid;
  border-color: #940c64;
  border-width: 1px;
  border-top: 10px;
  border-left: 0px;
  border-right: 0px;
}

.myModal {
  max-width: 1000px !important;
}
